/* styles.css */
.select__input{
    text-align: left;
  }

/* .text-subir-ficha{
    font-size: 12px;
    color: red;
}

@media screen and (max-width: 371px) {
   .text-subir-ficha{
    font-size: 40px;
    color: red;
   }
} */